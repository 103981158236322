var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('edit-dialog',{attrs:{"value":_vm.value,"headline-text":"Edit Transit","inputs-valid":!invalid},on:{"accept":_vm.saveChanges,"input":function($event){return _vm.$emit('input', $event)}}},[_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.availableStatuses,"label":"Status"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),(_vm.sendInTransitEmail)?_c('v-alert',{attrs:{"dense":"","outlined":"","color":"primary"}},[_c('strong',[_vm._v("In Transit")]),_vm._v(" email will be sent ")]):_vm._e(),_c('v-switch',{attrs:{"disabled":!_vm.deliveredStatuses.includes(_vm.status),"label":"Send Outbound Delivered Notification"},model:{value:(_vm.sendDeliveredEmail),callback:function ($$v) {_vm.sendDeliveredEmail=$$v},expression:"sendDeliveredEmail"}}),_c('v-select',{attrs:{"label":"Broker","item-text":"name","item-value":"url","items":_vm.brokerOptions,"clearable":""},model:{value:(_vm.broker),callback:function ($$v) {_vm.broker=$$v},expression:"broker"}}),_c('v-select',{attrs:{"required":"","label":"Carrier*","item-text":"name","item-value":"url","items":_vm.carrierOptions,"clearable":""},model:{value:(_vm.carrier),callback:function ($$v) {_vm.carrier=$$v},expression:"carrier"}}),_c('ValidationProvider',{attrs:{"rules":"two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"number","label":"Rate Quote","error-messages":errors},model:{value:(_vm.rateQuote),callback:function ($$v) {_vm.rateQuote=$$v},expression:"rateQuote"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-text-field',{attrs:{"type":"number","label":"Actual Cost","error-messages":errors},model:{value:(_vm.actualCost),callback:function ($$v) {_vm.actualCost=$$v},expression:"actualCost"}}),_c('v-btn-toggle',{attrs:{"dense":"","mandatory":""},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}},_vm._l((_vm.currencies),function(cur){return _c('v-btn',{key:cur},[_vm._v(" "+_vm._s(cur)+" ")])}),1)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"two_decimal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('v-text-field',{attrs:{"type":"number","label":"Additional Fees","error-messages":errors},model:{value:(_vm.additionalFees),callback:function ($$v) {_vm.additionalFees=$$v},expression:"additionalFees"}}),_c('v-btn-toggle',{attrs:{"dense":"","mandatory":""},model:{value:(_vm.additionalFeesCurrency),callback:function ($$v) {_vm.additionalFeesCurrency=$$v},expression:"additionalFeesCurrency"}},_vm._l((_vm.currencies),function(cur){return _c('v-btn',{key:cur},[_vm._v(" "+_vm._s(cur)+" ")])}),1)],1)]}}],null,true)}),_c('ValidationProvider',{directives:[{name:"show",rawName:"v-show",value:(_vm.transit.transit_type === 'OUT'),expression:"transit.transit_type === 'OUT'"}],attrs:{"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Shipment Details","row-height":"3","error-messages":errors},model:{value:(_vm.shipmentDetails),callback:function ($$v) {_vm.shipmentDetails=$$v},expression:"shipmentDetails"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Notes","row-height":"3","error-messages":errors},model:{value:(_vm.notes),callback:function ($$v) {_vm.notes=$$v},expression:"notes"}}),_c('p',{staticClass:"text-center red--text font-italic"},[_vm._v(" These notes only appear on the Transit View ")])]}}],null,true)}),_c('date-input',{attrs:{"label":"Shipped Date"},model:{value:(_vm.pickupDate),callback:function ($$v) {_vm.pickupDate=$$v},expression:"pickupDate"}}),_c('date-input',{attrs:{"label":"Delivered Date"},model:{value:(_vm.deliveryDate),callback:function ($$v) {_vm.deliveryDate=$$v},expression:"deliveryDate"}}),(!_vm.isUnshipped || _vm.status !== 'NE')?_c('v-alert',{attrs:{"dense":"","color":"warning","outlined":""}},[_vm._v(" Manifests are only available for modification while transit is 'NEW' ")]):_vm._e(),(_vm.isUnshipped)?_c('div',[_c('span',[_vm._v(" Lot Manifests ")]),_c('lot-manifest-detail-list',{attrs:{"lot-manifests":_vm.lotManifests,"append-icon":"mdi-delete-outline"},on:{"clicked":_vm.removeLotManifest}}),_c('lot-manifest-search-field',{on:{"selected":_vm.addLotManifest}}),_c('span',[_vm._v(" Inventory Manifests ")]),_c('inventory-manifest-detail-list',{attrs:{"inventory-manifests":_vm.inventoryManifests,"append-icon":"mdi-delete-outline"},on:{"clicked":_vm.removeInventoryManifest}}),_c('inventory-manifest-search-field',{on:{"selected":_vm.addInventoryManifest}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }