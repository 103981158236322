<template>
  <unauthorized
    :authorized="$store.getters.can('view-broker')"
    message="Not Authorized to view Trips"
  >
    <base-title title="Broker List" />
    <search-filters
      v-model="search"
      :chips="chips"
      @clearChip="clearChipProp"
      @clearFields="clearFields"
      @submitSearch="routePage"
    >
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="ID"
            v-model="id"
            type="number"
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            label="Name"
            v-model="name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-autocomplete
            label="Status"
            v-model="status"
            clearable
            :items="$store.getters['getBusinessStatus']"
            item-text="text"
            item-value="value"
          />
        </v-col>
      </v-row>
    </search-filters>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
    <v-data-table
      multi-sort
      :headers="headers"
      :items="resultList"
      :server-items-length="totalCount"
      :options.sync="pagination"
      :footer-props="{ 'items-per-page-options': [20] }"
      @page-count="pageCount = $event"
      hide-default-footer
    >
      <template v-slot:item.id="{ item }">
        <router-link-id
          :id="item.id"
          name="broker-view"
        />
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="getTextColor(item.status_text)"
        >
          {{ item.status_text }}
        </v-chip>
      </template>
    </v-data-table>
    <result-pagination
      v-if="pagination"
      v-model="pagination.page"
      :pages="Math.ceil(totalCount / 20)"
    />
  </unauthorized>
</template>

<script>
import axios from 'axios'
import chipsMixin from '@/base/filter/chipsMixin'
import paginationMixin from '@/base/filter/paginationMixin'
import SearchFilters from '@/base/filter/SearchFilters'
import ResultPagination from '@/base/components/ResultPagination'

export default {
  components: { ResultPagination, SearchFilters },
  mixins: [chipsMixin, paginationMixin],
  name: 'BrokerList',
  data: function () {
    return {
      pageName: 'broker-list',
      headers: [
        { text: 'Id', sortable: true, value: 'id' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Status', sortable: true, value: 'status' },
        { text: 'Website', sortable: true, value: 'website' },
        { text: 'Phone Number', sortable: true, value: 'phone_number' },
        { text: 'Contact Name', sortable: true, value: 'contact_name' },
        { text: 'Email', sortable: false, value: 'email' }
      ],

      ...this.chipPropagation([
        { name: 'ID', prop: 'id', type: String, query: 'id' },
        { name: 'Name', prop: 'name', type: String, query: 'name__icontains' },
        { name: 'Status', prop: 'status', type: String, query: 'status' }
      ])
    }
  },
  watch: {
    // observes and updated options
    pagination: function (updated) {
      this.routePage(updated)
    }
  },
  methods: {
    getTextColor () {
      return 'grey lighten-2'
    },
    async executeSearch () {
      const params = this.getQueryParams()
      axios.get('/logistics/brokers/', { params }).then(this.setResponseResults)
    }
  },
  mounted () {
    this.reflectRouteParams()
    this.executeSearch()
  }
}
</script>
